<template>
  <div>
    <dialog-pessoa
      :mostrar="mostrarDialogAdicionarPessoa"
      @fechar="mostrarDialogAdicionarPessoa = false"
      @salvar:dados="salvarPessoa"
    />

    <v-card :loading="carregandoBuscar || carregandoGrupos" elevation="1">
      <v-toolbar flat dense color="grey lighten-3">
        <v-toolbar-title class="grey lighten-3 text-h4">
          # {{ userToEdit.id || "--" }}
        </v-toolbar-title>
      </v-toolbar>
      <validation-observer ref="observer">
        <v-form class="mt-4">
          <v-container class="">
            <v-row>
              <v-col cols="12" md="9">
                <v-row>
                  <v-col cols="9" md="6" class="mr-0 pr-0">
                    <validation-provider v-slot="{ errors }" vid="pessoa_id">
                      <autocomplete-pessoas
                        :pessoa-adicionada="pessoaAdicionada"
                        :error-messages="errors"
                        @change="pessoaSelecionada"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      icon
                      color="success"
                      @click="mostrarDialogAdicionarPessoa = true"
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="5">
                    <validation-provider v-slot="{ errors }" vid="nome_usuario">
                      <v-text-field
                        v-model="usuarioNovo.nome_usuario"
                        outlined
                        dense
                        hide-details="auto"
                        :disabled="carregandoBuscar"
                        label="Usuário"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="pr-md-0" v-if="store.getters['usuario/temPermissao']('permissao-administrador-safety')">
                    <validation-provider v-slot="{ errors }" vid="empresa_id">
                      <v-select
                        v-model="usuarioNovo.empresa_id"
                        :items="empresas"
                        item-text="descricao"
                        item-value="id"
                        outlined
                        dense
                        label="Empresa"
                        hide-details="auto"
                        :loading="carregandoBuscarEmpresas"
                        :disabled="carregandoBuscarEmpresas || carregandoBuscar"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6" class="pr-md-0" v-else>
                      <v-select
                        v-model="usuarioNovo.empresa_id"
                        :items="[ usuario.empresa ]"
                        item-text="descricao"
                        item-value="id"
                        outlined
                        dense
                        label="Empresa"
                        hide-details="auto"
                        disabled
                      />
                  </v-col>
                  <v-col cols="1" class="d-none d-md-flex" />
                  <v-col cols="8" md="4">
                    <validation-provider v-slot="{ errors }" vid="imagem">
                      <v-file-input
                        v-model="usuarioNovo.imagem"
                        truncate-length="15"
                        outlined
                        dense
                        show-size
                        accept="image/*"
                        hide-details="auto"
                        :disabled="carregandoBuscar"
                        label="Imagem"
                        prepend-icon=""
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col v-if="usuarioNovo.imagem_usuario" cols="4" md="1">
                    <v-avatar size="40" tile>
                      <v-img
                        :src="caminhoImagens + usuarioNovo.imagem_usuario"
                      />
                    </v-avatar>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-if="!editar && !carregandoBuscar" cols="12" md="3">
                <validation-provider v-slot="{ errors }" vid="senha">
                  <v-text-field
                    v-model="usuarioNovo.senha"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="carregandoBuscar"
                    label="Senha"
                    type="password"
                    :error-messages="errors"
                    hint="Mínimo de 10 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caracter especial"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-switch
                  v-model="usuarioNovo.status"
                  class="mt-0 pt-0"
                  :disabled="carregandoBuscar"
                  :true-value="1"
                  :false-value="0"
                  label="Ativo"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3 class="display-2 mt-0">Grupos</h3>
              </v-col>
            </v-row>
            <v-row class="mt-md-2">
              <v-col
                v-for="grupo in listagemGrupos"
                :key="grupo.id"
                cols="12"
                md="3"
                class="py-1"
              >
                <v-checkbox
                  v-model="gruposSelecionados"
                  class="pa-0 ma-0"
                  :label="grupo.name"
                  :value="grupo.id"
                  :disabled="carregandoBuscar"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </validation-observer>
      <v-card-actions>
        <v-row
          v-if="
            $temPermissao('usuarios-alteracao') ||
            $temPermissao('usuarios-insercao')
          "
        >
          <v-col cols="12" class="text-left">
            <btn-salvar
              :disabled="carregandoBuscar"
              :carregando="carregandoSalvar"
              @click="salvar"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import _ from "lodash";
import gruposApi from "@/api/grupos";
import empresasApi from "@/api/empresas";
import store from '@/store'
import { mapState } from 'vuex'

export default {
  components: {
    AutocompletePessoas: () =>
      import("@/components/input/InputAutocompletePessoas"),
    DialogPessoa: () => import("@/components/dialog//pessoa/DialogPessoa"),
  },

  props: {
    validacaoFormulario: {
      type: Object,
      default: () => {
        return {};
      },
    },
    carregandoSalvar: {
      type: Boolean,
      default: false,
    },
    carregandoBuscar: {
      type: Boolean,
      default: false,
    },
    userToEdit: {
      type: Object,
      default: () => {
        return {};
      },
    },
    grupos: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      carregandoGrupos: false,
      mostrarDialogAdicionarPessoa: false,
      carregandoBuscarEmpresas: false,
      pessoaAdicionada: null,
      listagemGrupos: {},
      gruposSelecionados: [],
      empresas: [],
      caminhoImagens:
        process.env.VUE_APP_URL_SERVIDOR + "storage/imagens_usuario/",
      usuarioNovo: {
        pessoa_id: null,
        nome_usuario: null,
        senha: null,
        status: 1,
      },
      store: store
    };
  },

  computed: {
    ...mapState('usuario', ['usuario']),

    editar() {
      return !_.isEmpty(this.userToEdit);
    },
  },

  watch: {
    validacaoFormulario(val) {
      if (_.isEmpty(val)) return false;

      return this.$refs.observer.setErrors(val);
    },

    userToEdit: {
      immediate: true,
      handler(val) {
        if (_.isEmpty(val)) return;

        const userToEdit = val;

        userToEdit.pessoa_id = userToEdit.pessoa.id;

        delete userToEdit.falha_autenticacao;
        delete userToEdit.inteligencia;
        delete userToEdit.tipo_usuario;

        this.usuarioNovo = userToEdit;
        this.pessoaAdicionada = userToEdit.pessoa;
      },
    },

    grupos(grupos) {
      if (_.isEmpty(grupos)) return false;

      this.gruposSelecionados = Object.values(
        Object.keys(grupos).map((key) => grupos[key].id)
      );
    },
  },

  mounted() {
    this.buscarGrupos()

    if (this.store.getters['usuario/temPermissao']('permissao-administrador-safety')) {
      this.buscarEmpresas()
    }

    if (!this.store.getters['usuario/temPermissao']('permissao-administrador-safety')) {
      this.usuarioNovo.empresa_id = this.usuario.empresa_id
    }
  },

  methods: {
    salvar() {
      this.$refs.observer.reset()
      this.$emit("salvar", this.usuarioNovo, this.gruposSelecionados)
    },

    salvarPessoa(pessoa) {
      this.mostrarDialogAdicionarPessoa = false;
      this.pessoaAdicionada = pessoa;
    },

    pessoaSelecionada(pessoa) {
      this.usuarioNovo.pessoa_id = pessoa.id;
    },

    async buscarGrupos() {
      try {
        this.carregandoGrupos = true;

        const resposta = await gruposApi.listar();

        this.listagemGrupos = resposta.data.grupos;
      } catch (e) {
        this.$router.push("/usuarios", () =>
          this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) })
        );
      } finally {
        this.carregandoGrupos = false;
      }
    },

    async buscarEmpresas() {
      try {
        this.carregandoBuscarEmpresas = true;

        const resposta = await empresasApi.listar();

        this.empresas = resposta.data.data;
      } catch (e) {
        this.$router.push("/usuarios", () =>
          this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) })
        );
      } finally {
        this.carregandoBuscarEmpresas = false;
      }
    },
  },
}
</script>
